<template lang="pug">
  div.transport-wrapper
    slot(name="activator" :toggle="toggle")
      v-btn(small color='primary'
        @click="toggle"
        :disabled="disabled"
        outlined
      ) {{ activatorText }}
    app-dialog(v-model="showing" scrollable width=320 :title="title")
      template(#dialog-body)
        v-card
          v-card-text
            app-select(
              :options="transportTypes"
              v-model="formData.transport_type"
              placement="bottom-start"
              text
              hide-arrow
              placeholder="Transport type"
            ).transport-type-select.full-width
              app-button(icon slot="before")
                <i class="material-icons" v-if="!formData.transport_type">keyboard_arrow_down</i>
                transport-icon(:transportType="formData.transport_type" v-else )
              template(v-slot:option="{option} ")
                transport-icon(:transportType="option")
                span.pl-5 {{ option }}

      template(#dialog-footer)
        v-spacer
        app-button(color="#f4f4f4" hide-shadow @click.native="updateShowing(false)").mr-2 Close
        app-button(color="primary" @click.native="submit") Add
</template>

<script>
import errorsMixin from '@/mixins/errors.mixin'
import FormErrors from '@/util/form-errors'
import showingMixin from '@/mixins/showing.mixin'
import { TRANSPORT_TYPE_LIST } from '../../course_managment/core/cm-const'
import CandidatesActionsService from '../../candidates/core/candidates-actions-service'

export default {
  name: 'CourseDayBulkTransportConfig',

  mixins: [errorsMixin, showingMixin],

  props: {
    disabled: Boolean,
    activeItems: Array,
    title: {
      type: String,
      default: 'Bulk transport config'
    },
    activatorText: {
      type: String,
      default: 'Bulk transport config'
    },
    defaultTransport: {
      type: String,
      validator: function (val) {
        if (!val) return true
        return TRANSPORT_TYPE_LIST.includes(val)
      }
    },
    courseDayID: {type: Number, default: null}
  },

  data: () => ({
    formData: {
      transport_type: null
    },
    formErrors: new FormErrors(),
    transportTypes: TRANSPORT_TYPE_LIST
  }),

  methods: {
    submit() {
      if (!this.validate(this.formErrors)) {
        this.$notify({text: 'Invalid fields', type: 'error'})
        return
      }
      this.send()
    },

    validate(validator) {
      validator.resetFields()
      if (!this.formData.transport_type) validator.invalidField('transport_type', 'field required')
      return validator.isValid()
    },

    async send() {
      try {
        let ids = this.activeItems.map(item => item.ID)
        let res = await new CandidatesActionsService().bulkAssignTransportType(ids, this.courseDayID, this.formData)
        this.$notify({text: 'Transport config updated', type: 'success'})
        this.updateShowing(false)
        this.$emit('send', res)
      } catch (error) {
        console.log(error)
        this.processErrorWithForm(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM,
          formErrors: this.formErrors,
          apiAttributes: {
            transport_type: 'transport_type'
          }
        });
      }
    },

    reset() {
      this.formData = {
        transport_type: null
      }
    },
  },

  watch: {
    showing(isShowing) {
      if (!isShowing) {
        this.reset()
        return
      }
      if (this.defaultTransport) this.formData.transport_type = this.defaultTransport
    }
  },

  components: {
    transportIcon: () => import('@/components/global/TransportIcon.vue'),
    appButton: () => import('@/components/global/actions/BaseButton.vue'),
    appSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    appDialog: () => import('@/components/global/actions/BaseDialog.vue')
  }
}
</script>

<style lang="scss">
.transport-type-select {
  cursor: pointer;
  display: flex;
  align-items: center;
  padding-left: 4px;
  height: 41px;
  border: 1px solid $border-color;
  border-radius: 4px;

  .v-popover {
    width: 100%;
  }

  input {
    color: $input-color;
  }
}


.transport-wrapper {
  min-width: 24px;
  min-height: 10px;
}
</style>
